import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import AppManager from "@img/applications-solid.svg";
import Home from "@img/house-solid.svg";
import InfrastructureManager from "@img/mec-sites-solid.svg";
import SIMManager from "@img/sim-card-solid.svg";
import { InlineLoading } from "@src/Components/Loading/Loading";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";
import { useLandingPage } from "@src/Hooks/Permissions/landingPage";
import { useLandingPermissions } from "@src/Landing/landingPermissions";
import { QuickNavMenuState } from "@src/quickNavMenuState";

const iconStyles = css`
  height: 22px;
  fill: ${({ theme }) => theme.primary};
`;

const HomeIcon = styled(Home)`
  ${iconStyles}
  height: 20px;
`;
const AppIcon = styled(AppManager)`
  ${iconStyles}
`;
const InfraIcon = styled(InfrastructureManager)`
  ${iconStyles}
`;
const SimIcon = styled(SIMManager)`
  ${iconStyles}
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-right: auto;
  gap: 10px;

  & a {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

const MenuLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  padding: 6px 10px;
  align-items: center;
  font-weight: 600;
  border-radius: 3px;

  & span {
    margin: 0 auto;
    text-align: center;
  }

  &.active,
  &:hover:not(.active) {
    fill: white;
    color: white;
    border: 1px solid ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.primary};
  }
`;
const ApplicationsLink = styled(MenuLink)`
  gap: 6px;
`;

export function QuickNavMenu(quickNavProps: QuickNavMenuState) {
  const { allowed: showLanding } = useLandingPage();

  if (!showLanding) return null;

  const { selectedNetwork, loadingNetworks, selectedCore, loadingCores } = quickNavProps;

  if ((!selectedNetwork && loadingNetworks) || (!selectedCore && loadingCores)) {
    return <InlineLoading />;
  }

  return <QuickNavMenuInner {...quickNavProps} />;
}

function QuickNavMenuInner(props: QuickNavMenuState) {
  const { appManager, infrastructureManager, mpnManager } = useLandingPermissions({
    networkId: props.selectedNetwork?.uid,
    coreId: props.selectedCore?.uid
  });
  const orgCtx = useOrgCtx();

  const { org: defaultOrg } = useDefaultOrg();
  const org = orgCtx ?? defaultOrg;

  return (
    <LinksWrapper>
      <MenuLink to={`/app/${org}/home`}>
        <HomeIcon />
        <span>Home</span>
      </MenuLink>
      {appManager.allowed && (
        <ApplicationsLink to={appManager.url}>
          <AppIcon />
          <span>Applications</span>
        </ApplicationsLink>
      )}
      {infrastructureManager.allowed && (
        <MenuLink to={infrastructureManager.url}>
          <InfraIcon />
          <span>Sites</span>
        </MenuLink>
      )}
      {mpnManager.allowed && (
        <MenuLink to={mpnManager.url}>
          <SimIcon />
          <span>Networks</span>
        </MenuLink>
      )}
    </LinksWrapper>
  );
}
